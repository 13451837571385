<template>
  <v-app :style="colorVapp">
    <onebox_toolbar></onebox_toolbar>
    <v-content>
      <v-overlay :value="processloader" z-index="2" color="black" dark>
        <v-progress-circular indeterminate size="64" color="white"></v-progress-circular>
        <span :style="colorProgress">&nbsp; loading</span>
      </v-overlay>
      <v-list nav :color="color.BG" class="px-4" v-if="resolutionScreen < 500">
        <v-card rounded="xl" class="elevation-0 d-flex align-center" height="40">
          <v-list-item class="text-left">
            <v-list-item-avatar class="text-center ma-0">
              <v-avatar :color="color.theme" size="25">
                <v-icon dark size="20" @click="fn_goto_mypackage()">mdi-keyboard-backspace</v-icon>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="pl-3 pt-1 pb-1">
                <span :style="headerPage">{{ $t("packagenew.purchase_package") }}</span>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-card>
        <br>
        <v-layout>
          <v-flex xs6>
            <v-btn :color="color.theme" dark block @click="fn_goto_mypackage()">
              {{ $t("packagenew.my_package") }}
            </v-btn>
          </v-flex>
          <v-flex xs6 class="ml-4">
            <v-btn :color="color.theme" dark block @click="fn_goto_packagehistory()">
              {{ $t("packagenew.package_history") }}
            </v-btn>
          </v-flex>
        </v-layout>
      </v-list>
      <v-list nav :color="color.BG" v-else>
        <v-list-item class="text-left">
          <v-list-item-avatar class="text-center">
            <v-avatar :color="color.theme" size="35">
              <v-icon dark size="20" @click="fn_goto_mypackage()">mdi-chevron-left</v-icon>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="pl-3 pt-1 pb-1">
              <span :style="headerPage">{{ $t("packagenew.purchase_package") }}</span>
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-flex>
              <v-btn :color="color.theme" dark small class="mr-2" @click="fn_goto_mypackage()">
                {{ $t("packagenew.my_package") }}
              </v-btn>
              <div v-if="resolutionScreen < 400" class="mb-2"></div>
              <v-btn :color="color.theme" dark small @click="fn_goto_packagehistory()">
                {{ $t("packagenew.package_history") }}
              </v-btn>
            </v-flex>
          </v-list-item-action>
        </v-list-item>
      </v-list>

      <v-card v-if="resolutionScreen >= 400" class="elevation-0" :color="color.BG" id="thiscontainer_package">
        <v-card-text class="pa-0">
          <v-container fluid>
            <v-layout row wrap justify-center v-if="loaddataprogress === true">
              <v-flex lg12 class="ma-2 ml-4 mr-4">
                <div class="text-center">
                  <v-progress-circular :size="50" :color="color.theme" indeterminate></v-progress-circular>
                </div>
              </v-flex>
            </v-layout>
            <v-layout row wrap justify-center v-else>
              <v-flex lg12 xs12 class="mr-4 ml-4">
                <v-card class="mx-auto" outlined id="borderradius">
                  <v-layout row wrap justify-center>
                    <v-flex xs9 lg9>
                      <v-list-item class="ml-4">
                        <v-list-item-content>
                          <v-layout row wrap justify-center>
                            <v-flex lg12 xs12>
                              <v-row>
                                <v-col class="ml-4 mt-6" cols="6">
                                  <h3 class="text-left mt-2">
                                    {{ $t("packagenew.current_mystorage") }} {{ total_storage_business }}
                                  </h3>
                                </v-col>
                                <v-col cols="4" class="mt-4">
                                  <v-progress-linear
                                    :color="color_storage"
                                    height="10"
                                    rounded
                                    :value="percentstorage"
                                  ></v-progress-linear
                                  ><br />
                                  <h4 class="ma-2 ml-2">
                                    {{ $t("packagenew.used_space") }} {{ use_storage_business }}/{{ total_storage_business }}
                                  </h4>
                                </v-col>
                              </v-row>
                            </v-flex>
                          </v-layout>
                        </v-list-item-content>
                      </v-list-item>
                    </v-flex>
                    <v-flex xs3 lg3>
                      <v-img :src="require('@/assets/img/package2.png')" max-width="120" max-height="120" class="mt-4 mb-4 ml-6" />
                    </v-flex>
                  </v-layout>
                </v-card>
                <br />
                <v-divider></v-divider>
                <h2 class="text-center mt-10 mb-1" :style="darkModeText">
                  <!-- คำนวนค่าบริการ Storage ของคุณ -->
                  {{ $t("packagenew.service_charge") }} 
                </h2>
                <br />
              </v-flex>
              <br />
              <!-- คำนวน storage -->
              <v-layout row wrap justify-center>
                <v-flex lg8 xs11 class="text-center">
                  <v-row class="text-center">
                    <v-col cols="12" class="py-2">
                      <v-btn-toggle
                        v-model="packageTypeSelected"
                        :color="packageTypeSelected ? '#2D4E9A' : 'grey lighten-1'"
                        borderless
                        mandatory
                      >
                        <v-btn value="M">
                          <span>{{ $t("packagenew.month") }}</span>
                        </v-btn>
                        <v-btn value="Y">
                          <span>{{ $t("packagenew.year") }}</span>
                        </v-btn>
                      </v-btn-toggle>
                    </v-col>
                  </v-row>
                  <v-card class="mx-auto mt-8" outlined>
                    <br/>
                    <v-row>
                      <v-col cols="6">
                        <v-layout row wrap justify-center>
                          <v-flex xs4 lg4>
                            <v-btn
                              class="white--text elevation-0 mt-6"
                              fab
                              large
                              :color="color.theme"
                              :disabled="storage_citizen <= 10"
                              @click.prevent="fn_minus_package(parseInt(storage_citizen))"
                            >
                              <v-icon>mdi-minus</v-icon>
                            </v-btn>
                          </v-flex>
                          <v-flex xs4 lg4>
                            <v-text-field
                              outlined
                              class="ml-2 mt-6 text-center"
                              v-model="storage_citizen"
                              suffix="GB"
                              @input="fn_cal_price()"
                              readonly
                            ></v-text-field>
                               <!-- :rules="requiredstorage_citizen"
                              required
                              :error-messages="storage_citizenError"
                              @input="fn_cal_price(),$v.storage_citizen.$touch()"
                              @blur="$v.storage_citizen.$touch()" -->
                          </v-flex>
                          <v-flex xs4 lg4>
                            <v-btn
                              class="white--text elevation-0  mt-6"
                              fab
                              large
                              :color="color.theme"
                              @click.prevent="fn_plus_package(parseInt(storage_citizen))"
                            >
                              <v-icon>mdi-plus</v-icon>
                            </v-btn>
                          </v-flex>
                        </v-layout>
                      </v-col>
                   
                      <v-divider vertical></v-divider>
                      <v-col cols="5" class="ml-6">
                        <div class="calculated-price tk-prometo">
                          <span class="currency-label text-h3 text--primary" > ฿ </span>
                          <span id="priceLabelPerMonth" class="text-h3 text--primary"> {{ packageTypeSelected === 'M' ? numberWithCommas(price_storage_citizen) : numberWithCommas(price_storage_citizen *12)}}</span>
                        </div>
                        <div>
                          <span class="currency-label" v-if="packageTypeSelected === 'M'"> / {{ $t("my_package.month") }}</span>
                          <span class="currency-label" v-else> / {{ $t("my_package.year") }}</span>
                        </div>
                        <div class="my-2">
                          <v-btn large :color="color.theme" dark @click="fn_checkchoose_taxinvoice()">
                           {{ $t("admintoolbar.buystorage") }}
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                    <br/>
                  </v-card>
                <v-flex lg12 xs12 class="mr-4 ml-4">
                <p class="text-right font-weight-regular mt-2" style="color:red;">
                  {{ $t("packagenew.text_notvat") }}
                </p>
                <br />
              </v-flex>
                </v-flex>
              </v-layout>

              <!-- ที่อยู่ใบกำกับภาษี -->
              <!-- <v-divider v-if="taxInvoice === true"></v-divider> -->
             
              <v-flex lg12 xs12 class="mr-4 ml-4" v-if="taxInvoice === true">
                   <h2 class="text-left mt-8" :style="darkModeText">
                  {{ $t("taxinvoice_address.taxinvoice_address") }}
                </h2>
                <v-card class="mx-auto mt-8" outlined id="borderradius">
                     <div>
                    <v-layout xs12 lg12 row wrap>
                      <v-flex xs12 lg12 class="pl-8 pt-4"><p>{{$t("taxinvoice_address.purchaser") + "     " + name_invoice_th}}</p></v-flex>
                    </v-layout>
                    <v-layout xs12 lg12 row wrap>
                      <v-flex xs12 lg12 class="pl-8 "><p>{{$t("taxinvoice_address.address")  + "     " +  address}}</p></v-flex>
                    </v-layout>
                    <v-layout xs12 lg12 row wrap>
                      <v-flex xs12 lg12 class="pl-8 "><p>{{$t("taxinvoice_address.tax_identification") + "      " +  tax_num + "   " + branch_name }}</p></v-flex>
                    </v-layout>
                  </div>
           
                </v-card>
              </v-flex>
              
               <v-flex lg12 xs12 class="mr-4 ml-4">
                 <div class="text-center">
                  <v-btn
                    class="text-decoration-underline ma-6"
                    :style="taxInvoice == true ? 'color:#5AB685;  fornt-size:30;' : 'color:#1279BC; fornt-size:30;'"
                    @click="fn_tax_invoice()"
                    text
                  >
                    {{
                      taxInvoice == true
                        ? $t("taxinvoice_address.edit_tax")
                        : $t("taxinvoice_address.click_here")
                    }}
                  </v-btn>
                </div> 
               </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
      </v-card>
      
      <!-- จอมือถือ -->
      <v-card v-else class="elevation-0" :color="color.BG" id="thiscontainer_package">
        <v-card-text class="pa-0">
          <v-container fluid>
            <v-layout row wrap justify-center v-if="loaddataprogress === true">
              <v-flex lg12 class="ma-2 ml-4 mr-4">
                <div class="text-center">
                  <v-progress-circular :size="50" :color="color.theme" indeterminate></v-progress-circular>
                </div>
              </v-flex>
            </v-layout>
            <v-layout row wrap justify-center v-else>
              <v-flex lg12 xs12 class="mr-4 ml-4">
                <!-- พื้นที่จัดเก็บข้อมูลปัจจุบัน [new] -->
                <v-card class="py-4" outlined id="borderradius">
                  <v-list-item>
                    <v-icon :color="color.theme" class="pr-2">storage</v-icon>
                    <h3 :style="'color:' + color.theme">{{ $t("packagenew.current_mystorage") }}</h3>
                    <v-spacer></v-spacer>
                    <v-chip color="#E9A016">
                      <h3 class="white--text">{{ total_storage_business }}</h3>
                    </v-chip>
                  </v-list-item>
                  <v-card-text class="py-0">
                    <v-progress-linear
                      :color="color_storage"
                      height="7"
                      rounded
                      :value="percentstorage"
                    ></v-progress-linear>
                    <p class="text-left mb-2">{{ $t("packagenew.used_space") }} {{ use_storage_business }}/{{ total_storage_business }}</p>
                  </v-card-text>
                </v-card>
                <!-- พื้นที่จัดเก็บข้อมูลปัจจุบัน -->
                <!-- <v-card class="mx-auto" outlined id="borderradius">
                  <v-layout row wrap justify-center>
                    <v-flex xs12 lg12>
                      <v-list-item class="ml-5">
                        <v-list-item-content>
                          <v-layout row wrap justify-center>
                            <v-flex lg12 xs12>
                              <v-row justify-center>
                                <v-col class="ml-6" cols="12">
                                  <h3 class="text-left">
                                    {{ $t("packagenew.current_mystorage") }}
                                  </h3><br>
                                  <h3 class="text-left">
                                    {{ total_storage_business }}
                                  </h3>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col class="ml-6" cols="6">
                                  <v-progress-linear
                                    :color="color_storage"
                                    height="10"
                                    rounded
                                    :value="percentstorage"
                                  ></v-progress-linear
                                  ><br />
                                  <h4>
                                    {{ $t("packagenew.used_space") }}
                                  </h4><br>
                                  <h4>
                                    {{ use_storage_business }}/{{ total_storage_business }}
                                  </h4>
                                </v-col>
                                <v-col cols="5">
                                  <v-img class="ml-3 " :src="require('@/assets/img/package2.png')" max-width="80" max-height="80" />
                                </v-col>
                              </v-row>
                            </v-flex>
                          </v-layout>
                        </v-list-item-content>
                      </v-list-item>
                    </v-flex>
                  </v-layout>
                </v-card> -->                
                <h3 class="text-center mt-10 mb-1" :style="darkModeText">
                  <!-- คำนวนค่าบริการ Storage ของคุณ -->
                  {{ $t("packagenew.service_charge") }} 
                </h3>
              </v-flex>
              <br />
              <!-- คำนวน storage -->
              <v-layout row wrap justify-center>
                <v-flex lg8 xs10 class="text-center">
                  <!-- <v-row class="text-center">
                    <v-col cols="12" class="py-2">
                      <v-btn-toggle
                        v-model="packageTypeSelected"
                        :color="packageTypeSelected ? '#2D4E9A' : 'grey lighten-1'"
                        borderless
                        mandatory
                      >
                        <v-btn value="M">
                          <span>{{ $t("packagenew.month") }}</span>
                        </v-btn>
                        <v-btn value="Y">
                          <span>{{ $t("packagenew.year") }}</span>
                        </v-btn>
                      </v-btn-toggle>
                    </v-col>
                  </v-row> -->                 
                  <v-card class="mx-auto pa-4" outlined id="borderradius">
                    <v-tabs
                      v-model="packageTypeSelected"
                      :color="color.theme"
                      left
                      class="mb-4"
                    >
                      <v-tab href="#M">{{ $t("packagenew.month") }}</v-tab>
                      <v-tab href="#Y">{{ $t("packagenew.year") }}</v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="packageTypeSelected">
                      <v-tab-item
                        v-for="i in ['M','Y']"
                        :key="i"
                        :value="i"
                      >
                      </v-tab-item>
                    </v-tabs-items>
                    <v-row>
                      <v-col cols="12">
                        <v-layout row wrap justify-center>
                          <v-flex xs4 lg4>
                            <v-btn
                              class="white--text elevation-0"
                              fab
                              small                              
                              :color="color.theme"
                              :disabled="storage_citizen <= 10"
                              @click.prevent="fn_minus_package(parseInt(storage_citizen))"
                            >
                              <v-icon>mdi-minus</v-icon>
                            </v-btn>
                          </v-flex>
                          <v-flex xs4 lg4>
                            <v-text-field
                              outlined
                              hide-details
                              dense
                              class="ml-2 mr-2 text-center"
                              v-model="storage_citizen"
                              suffix="GB"
                              @input="fn_cal_price()"
                              readonly
                            ></v-text-field>
                               <!-- :rules="requiredstorage_citizen"
                              required
                              :error-messages="storage_citizenError"
                              @input="fn_cal_price(),$v.storage_citizen.$touch()"
                              @blur="$v.storage_citizen.$touch()" -->
                          </v-flex>
                          <v-flex xs4 lg4>
                            <v-btn
                              class="white--text elevation-0"
                              fab
                              small
                              :color="color.theme"
                              @click.prevent="fn_plus_package(parseInt(storage_citizen))"
                            >
                              <v-icon>mdi-plus</v-icon>
                            </v-btn>
                          </v-flex>
                        </v-layout>
                      </v-col>
                      <v-divider></v-divider>
                      <v-col cols="12">
                        <div class="calculated-price tk-prometo">
                          <span class="currency-label text-h4 text--primary" > ฿ </span>
                          <span id="priceLabelPerMonth" class="text-h4 text--primary"> 
                            {{ packageTypeSelected === 'M' ? numberWithCommas(price_storage_citizen) : numberWithCommas(price_storage_citizen *12)}}
                          </span>
                          <span class="currency-label" v-if="packageTypeSelected === 'M'"> / {{ $t("my_package.month") }}</span>
                          <span class="currency-label" v-else> / {{ $t("my_package.year") }}</span>
                        </div>
                        <div class="mt-4">
                          <v-btn small :color="color.theme" dark @click="fn_checkchoose_taxinvoice()">
                           {{ $t("admintoolbar.buystorage") }}
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card>
                  <v-flex lg12 xs12 class="mr-4 ml-4">
                  <p class="text-left font-weight-regular mt-2" style="color:red;">
                    {{ $t("packagenew.text_notvat") }}
                  </p>
                  </v-flex>
                </v-flex>
              </v-layout>

              <!-- ที่อยู่ใบกำกับภาษี -->
              <!-- <v-divider v-if="taxInvoice === true"></v-divider> -->
             
              <v-flex lg12 xs12 class="mr-5 ml-4" v-if="taxInvoice === true">
                <h3 class="text-left mt-8 ml-2" :style="darkModeText">
                  {{ $t("taxinvoice_address.taxinvoice_address") }}
                </h3>
                <v-card class="mx-auto mt-8" outlined id="borderradius">
                  <div>
                    <v-layout xs12 lg12 row wrap>
                      <v-flex xs11 lg11 class="pl-9 pt-4"><p>{{$t("taxinvoice_address.purchaser") + "     " + name_invoice_th}}</p></v-flex>
                    </v-layout>
                    <v-layout xs12 lg12 row wrap>
                      <v-flex xs11 lg11 class="pl-9 "><p>{{$t("taxinvoice_address.address")  + "     " +  address}}</p></v-flex>
                    </v-layout>
                    <v-layout xs12 lg12 row wrap>
                      <v-flex xs11 lg11 class="pl-9 "><p>{{$t("taxinvoice_address.tax_identification") + "      " +  tax_num + "   " + branch_name }}</p></v-flex>
                    </v-layout>
                  </div>           
                </v-card>
              </v-flex>
              <v-flex lg12 xs12 class="mr-4 ml-4">
                <div class="text-center">
                  <v-btn v-if="taxInvoice == true"
                    class="text-decoration-underline my-6"
                    style="color:#5AB685;"
                    @click="fn_tax_invoice()"
                    text
                  >
                    <span class="text-wrap" style="width: 95%;">{{ $t("taxinvoice_address.edit_tax") }}</span>
                  </v-btn>
                  <v-btn
                    v-else
                    class="text-decoration-underline my-6"
                    style="color:#1279BC;"
                    @click="fn_tax_invoice()"
                    text
                  >
                    <span class="text-wrap" style="width: 95%;">{{ $t("taxinvoice_address.click_here") }}</span>
                  </v-btn>
                </div>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
      </v-card>
    </v-content>
    <checktaxinvioce
      :show="opendialogchecktaxinvioce"
      @close="opendialogchecktaxinvioce = false"
      @closedialog="(opendialogchecktaxinvioce = false),fn_buystorage_citizen(),(check_typeinvoice = 0)"
      @payment="(opendialogchecktaxinvioce = false),fn_tax_invoice(),(check_typeinvoice = 1)"
     
    ></checktaxinvioce>
    <dialogtaxinvoice
      :show="opendialogtaxinvioce"
      :checktaxbtn="check_typeinvoice"
      @closedialog="opendialogtaxinvioce = false, (check_typeinvoice = 0)"
      @closeandpay="opendialogtaxinvioce = false,fn_buystorage_citizen(), (check_typeinvoice = 0)"
      @closenotpay="(opendialogtaxinvioce = false),(check_typeinvoice = 0)"
    ></dialogtaxinvoice>
  </v-app>
</template>
<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import { mapState, mapGetters } from "vuex";
import InfiniteLoading from "vue-infinite-loading";
import "vue-full-screen-file-drop/dist/vue-full-screen-file-drop.css";
import VueFullScreenFileDrop from "vue-full-screen-file-drop";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import { slider, slideritem } from "vue-concise-slider";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import { required, maxLength, email } from "vuelidate/lib/validators";

const onebox_toolbar = () => import("../components/layout/layout-toolbar-new");
const checktaxinvioce = () => import("../components/optional/dialog-checktaxinvioce.vue");
const dialogtaxinvoice = () => import("../components/optional/dialog-tax-invoice-citizen.vue");

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});
export default {

   validations: { 
     storage_citizen: { required },
  },
  
  data: () => ({
    check_typeinvoice:0,
    opendialogtaxinvioce:false,
    opendialogchecktaxinvioce:false,
    loaddataprogress: false,
    price_storage_citizen: 35,
    storage_citizen: 10,
    sliderstorage_addon: 10,
    address: "",
    tax_num: "",
    noaddress: "",
    building: "",
    floor: "",
    road: "",
    subdistrict: "",
    district: "",
    province: "",
    postcode: "",
    typebusiness: "",
    name_business_th: "",
    typebusiness_eng: "",
    radios: "",
    branch_no: "",
    branch_name: "",
    email_business: "",
    tel_biz: "",
    mooban: "",
    no_moo: "",
    noroom: "",
    yaek: "",
    soi: "",
    fax: "",
    name_invoice_th: "",
    phone_number_biz: "",
    list_selected_package_main_showselect: [],
    listpackageupdate_detail_main: [],
    listpackageupdate_detail_addon: [],
    listpackageupdate_detail_r: [],
    listpackageupdate_detail_r_main: [],
    listpackageupdate_detail_r_addon: [],
    listpackageupdate_detail_update_addon: [],
    listpackageupdate_detail_update_main: [],
    execution: "",
    vat: 0,
    listpackage_addon_show_Update: [],
    listpackageupdate_detail: [],
    all_name: "",
    paid_dtm: "",
    total_amount: 0,
    total_amount_vat: 0,
    listpackage_current: [],
    opendialogshowdetailupdatepackage: false,
    opendialogchecktaxinvioce: false,
    dataaddon_Index_addon: "",
    dataaddon_Index: "",
    list_selected_package_main_update: [],
    listpackage_current_main: [],
    listpackage_current_addon: [],
    checktaxbtn: 0,
    pricetotal: 0,
    pricetotal_addon: 0,
    listpackage_addon_: [],
    listpackage_addon_show: [],
    list_selected_package_main: [],
    list_selected_package_main_show: [],
    setcolor: false,
    filemultipleaddon: [],
    statusmutipleaddon: false,
    search: "",
    percentstorage: 0,
    percentuser: 0,
    color_user: "",
    color_storage: "",
    num_package_limit: 0,
    total_storage_business: "",
    use_storage_business: "",
    total_user: 0,
    use_user: 0,
    listpackage_addon: [],
    id_mypackage: "",
    listpackage: [],
    mypackage: [],
    listpackage_total: [],
    URL_payment: "",
    packageTypeSelected: "M",
    model: null,
    list_packge: [],
    swiperOption: {
      slidesPerView: 3,
      spaceBetween: 30,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    },
    isFooterDesktop: true,
    isFooterMobile: false,
    showDataStorage: "",
    opentaxinvoice: false,
    taxInvoice: false,
    requiredstorage_citizen: [(value) => !!value || "โปรดระบุข้อมูล.", (value) => this.checkstorage(value) || "ข้อมูลไม่ถูกต้อง / Invalid data"],
  }),
  components: {
    InfiniteLoading,
    VueFullScreenFileDrop,
    onebox_toolbar,
    checktaxinvioce,
    dialogtaxinvoice,
    Swiper,
    SwiperSlide,
  },

  computed: {
    ...mapState(["username", "authorize", "account_active", "color", "storage_usage"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataStorageUsage",
      "dataStorageMax",
    ]),
    btnStates() {
      return this.buttons.map((btn) => btn.state);
    },
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    headergb() {
      return "font-size: 40px";
    },
    header() {
      return "font-size: 30px";
    },
    price_() {
      return "color:black;" + "font-weight: 600; font-size:28px;";
    },
    price_dark() {
      return "color:white;" + "font-weight: 600; font-size:28px;";
    },
    headerTable() {
      return "color:" + this.color.theme + ";" + "font-weight: 600; font-size:15px;";
    },
    headerPage() {
      return "color:" + this.color.theme + ";" + "font-size: 18px; font-weight: 600;";
    },
    colorVapp() {
      return "background:" + this.color.BG + ";";
    },
    colorSort() {
      return "color:" + this.color.alertText + ";";
    },
    colorProgress() {
      return "color:" + this.color.theme + ";";
    },
    darkModeText() {
      return this.$vuetify.theme.dark ? "color: white;" : "color: black"
    },
    // storage_citizenError() {
    //   const errors = [];
    //   if (!this.$v.storage_citizen.$dirty) return errors;
    //   !this.$v.storage_citizen.required && errors.push("โปรดระบุจำนวนstorage ที่ต้องหาร");
    //   return errors;
    // },
  },
  methods: {
    checkstorage(){
      const pattern = /^[0-9]{1,}$/i;
      if (pattern.test(value) == true) {
        return true;
      } else {
        return false;
      }
    },
    fn_check_tax_invoice() {
      // console.log("fn_check_tax_invoice");
      let payload = {
        business_id: this.dataAccountActive.type === 'Citizen' ? this.dataAccountId : this.dataAccountActive.business_info.business_id,
      };
      this.axios
        .post(process.env.VUE_APP_SERVICE_ADMIN + "/api/get_taxinvoic_address", payload)
        .then((response) => {
          if (response.data.status === "OK") {
            console.log("response",response);
            this.taxInvoice = true;
            this.address = response.data.address
            this.tax_num = response.data.result.taxid;
            this.noaddress = response.data.result.HouseNumber;
            this.building = response.data.result.BuildingName;
            this.floor = response.data.result.FloorNumber;
            this.road = response.data.result.StreetName;
            this.subdistrict = response.data.result.Thambol;
            this.district = response.data.result.Amphur;
            this.province = response.data.result.Province;
            this.postcode = response.data.result.PostCode;
            this.typebusiness = response.data.result.business_TitleName_th;
            this.name_business_th = response.data.result.business_name;
            // this.typebusiness_eng = response.data.result.business_TitleName_en;
            // this.radios = response.data.result.BranchNumber === "00000" ? "สำนักงานใหญ่" : "สาขาอื่นๆ";
            // this.branch_no = response.data.result.BranchNumber;
            // this.branch_name = response.data.result.branchname;
            this.email_business = response.data.result.email
            this.tel_biz = response.data.result.phone_numbers
            this.mooban = response.data.result.MooName
            this.no_moo = response.data.result.MooNumber
            this.noroom = response.data.result.RoomNumber
            this.yaek = response.data.result.Separate
            this.soi = response.data.result.SoiName
            this.fax = response.data.result.fax_number
            this.name_invoice_th = response.data.result.business_name
            this.phone_number_biz= response.data.result.telephone_number
          } else {
            this.taxInvoice = false;
          }
        })
        .catch((error) => {
          // console.log(error);
          this.loaddataprogress = false;
          Toast.fire({
            icon: "error",
            title: "ไม่สามารถเรียกไฟล์ได้ในขณะนี้",
          });
        });
    },
    fn_cal_price() {
      this.price_storage_citizen = this.storage_citizen * 3.5;
      // if(this.packageTypeSelected === 'M'){
      //    this.price_storage_citizen = this.storage_citizen * 3.5;
      // }else{
      //   this.price_storage_citizen = this.storage_citizen * 3.5*12;
      // }
    
    },
    fn_minus_package(price) {
      this.storage_citizen = parseInt(price -= 10)
      this.price_storage_citizen = parseInt(price) * 3.5;
      // if(this.packageTypeSelected === 'M'){
      //   this.price_storage_citizen = parseInt(price) * 3.5;
      // }else{
      //   this.price_storage_citizen = parseInt(price) * 3.5 *12;
      // }
      console.log(typeof this.price_storage_citizen);
       console.log("this.price_storage_citizen",this.price_storage_citizen);
      console.log("this.price_storage_citizen", this.price_storage_citizen);
    },
    fn_plus_package(price) {
      console.log(typeof price);
      this.storage_citizen = parseInt(price += 10)
      this.price_storage_citizen = parseInt(price) * 3.5;
      // if(this.packageTypeSelected === 'M'){
      //   this.price_storage_citizen = parseInt(price) * 3.5;
      // }else{
      //   this.price_storage_citizen = parseInt(price) * 3.5 *12;
      // }
      console.log(typeof this.price_storage_citizen);
       console.log("this.price_storage_citizen",this.price_storage_citizen);
      console.log("this.price_storage_citizen", this.price_storage_citizen);
    },
    fn_getstorage_user() {
      let payload = {
        account_id: this.dataAccountId,
      };
      // console.log("payload fn_getstorage_user", payload);
      this.axios.post(process.env.VUE_APP_SERVICE_ADMIN_BOX + "/api/get_conclusion_package", payload).then((response) => {
        if (response.data.status === "OK") {
          //storage
          this.total_storage_business = response.data.result.storage_business;
          this.use_storage_business = response.data.result.used_storage_business;
          // console.log("this.total_storage_business",this.total_storage_business);
          // console.log("this.use_storage_business",this.use_storage_business);

          //user
          this.total_user = response.data.result.number_of_users;
          this.use_user = response.data.result.used_users;
          // console.log("this.total_user",this.total_user);
          // console.log("this.use_user ",this.use_user );

          // คำนวณ % user
          let percentUsageUser = (this.use_user / this.total_user) * 100;
          this.percentuser = percentUsageUser === Infinity || percentUsageUser > 100 ? 100 : Math.ceil(percentUsageUser);
          // console.log("percentUsageUser", percentUsageUser);
          // console.log("this.percentuser",this.percentuser);

          //คำนวน % storage
          // let datastorage_total = this.total_storage_business.split(" ");
          // let datastorage_use = this.use_storage_business.split(" ");
          let percentUsageStorage =
            (this.convertstorageToByte(this.use_storage_business) / this.convertstorageToByte(this.total_storage_business)) * 100;
          this.percentstorage =
            percentUsageStorage === Infinity || percentUsageStorage > 100 ? 100 : Math.ceil(percentUsageStorage);
          // console.log("percentUsageStorage", percentUsageStorage);
          // console.log("this.percentstorage", this.percentstorage);

          // setสี
          this.color_user = this.percentageuser_setcolor(this.percentuser);
          this.color_storage = this.percentagestoage_setcolor(this.percentstorage);
        } else {
          Toast.fire({
            icon: "error",
            title: response.data.errorMessage,
          });
        }
      });
    },
    percentageuser_setcolor() {
      if (this.percentuser >= 0 && this.percentuser < 51) {
        return "#5AB685";
      } else if (this.percentuser >= 51 && this.percentuser < 81) {
        return "#E9A016";
      } else if (this.percentuser >= 81) {
        return "#D1392B";
      }
    },
    percentagestoage_setcolor() {
      if (this.percentstorage >= 0 && this.percentstoragee < 51) {
        return "5AB685";
      } else if (this.percentstorage >= 51 && this.percentstorage < 81) {
        return "#E9A016";
      } else if (this.percentstorage >= 81) {
        return "#D1392B";
      }
    },
    convertstorageToByte(_storage) {
      // console.log(_storage);
      let splitStorage = _storage.split(" ");
      let databyte = 0;

      if (splitStorage[1] === "KB") {
        databyte = typeof splitStorage[0] === "number" ? splitStorage[0] * 1024 : parseFloat(splitStorage[0]) * 1024;
      } else if (splitStorage[1] === "MB") {
        databyte = typeof splitStorage[0] === "number" ? splitStorage[0] * 1048576 : parseFloat(splitStorage[0]) * 1048576;
      } else if (splitStorage[1] === "GB") {
        databyte = typeof splitStorage[0] === "number" ? splitStorage[0] * 1073741824 : parseFloat(splitStorage[0]) * 1073741824;
      } else if (splitStorage[1] === "TB") {
        databyte =
          typeof splitStorage[0] === "number" ? splitStorage[0] * 1099511627776 : parseFloat(splitStorage[0]) * 1099511627776;
      } else {
        databyte = typeof splitStorage[0] === "number" ? splitStorage[0] : parseFloat(splitStorage[0]);
      }
      // console.log("convertstorageToByte",Math.ceil(databyte));
      return Math.ceil(databyte);
    },
    fn_checkchoose_taxinvoice(){
    // console.log(typeof(this.storage_citizen));
      if(this.taxInvoice === true){
        this.checktaxbtn = 1;
      }
      this.opendialogchecktaxinvioce = true;
    },
    async fn_buystorage_citizen(){
      //UAT
      // "package_id": this.packageTypeSelected === 'M' ? "c0bd665cc18e2fe5cd92daf78bae1b0d" : "a54d47b16c6d8509f038e2db936ac060" 
      // prod
      // "package_id": this.packageTypeSelected === 'M' ? "77a823b25ecd24a3d28664261e0b4e5f" : "c4db4cd8f1fdecbb6d48f82797a586ea" 

    console.log("this.check_typeinvoice",this.check_typeinvoice);
      if(this.listpackage_current.length != 0){
        this.URL_payment = "";
        let payload;
        console.log("----",process.env.NODE_ENV);
        if (process.env.NODE_ENV === "production") {
          payload = {
            account_id: this.dataAccountId,
            data_id: this.dataAccountActive.type === 'Citizen' ? this.dataAccountId : this.dataAccountActive.business_info.business_id,
            type: this.dataAccountActive.type === 'Citizen' ? 'citizen': 'business',
            order_desc: "",
            list_packge_main: [
                {
                  "package_id": this.packageTypeSelected === 'M' ? "77a823b25ecd24a3d28664261e0b4e5f" : "c4db4cd8f1fdecbb6d48f82797a586ea"   ,
                  "quantity": this.storage_citizen
                }
              ],

            list_packge_addon: [],
            execution:"P",
            type_invoice:this.check_typeinvoice === 1 ? "Y" : "N"
          };
        }else{
          payload = {
            account_id: this.dataAccountId,
            data_id: this.dataAccountActive.type === 'Citizen' ? this.dataAccountId : this.dataAccountActive.business_info.business_id,
            type: this.dataAccountActive.type === 'Citizen' ? 'citizen': 'business',
            order_desc: "",
            list_packge_main: [
                {
                  "package_id": this.packageTypeSelected === 'M' ? "77a823b25ecd24a3d28664261e0b4e5f" : "c4db4cd8f1fdecbb6d48f82797a586ea"  ,
                  "quantity": this.storage_citizen
                }
              ],

            list_packge_addon: [],
            execution:"P",
            type_invoice:this.check_typeinvoice === 1 ? "Y" : "N"
          };
        }
     // console.log("payload", payload);
        let auth = await gbfGenerate.generateToken();
        this.axios
          .post(process.env.VUE_APP_SERVICE_ADMIN_BOX + "/api/update_payment_package_storage", payload, {
            headers: { Authorization: auth.code },
          })
          .then((response) => {
            if (response.data.status === "OK") {
          
              console.log("response", response);
              this.status_response = 'OK';
              this.loaddataprogress = false;
              this.$emit("closedialog");
              if(this.execution === 'R'){
                this.opendialogconfirmupdatepackage = true;
              }else{
                this.URL_payment = response.data.result
                this.gotopay();
              }

            } else {
              Toast.fire({
                icon: "error",
                title: response.data.errorMessage,
              });
            }
          });
      }else{
        this.URL_payment = "";
        let payload;
        if (process.env.NODE_ENV === "production") {
          payload = {
            account_id: this.dataAccountId,
            data_id: this.dataAccountActive.type === 'Citizen' ? this.dataAccountId : this.dataAccountActive.business_info.business_id,
            type: this.dataAccountActive.type === 'Citizen' ? 'citizen': 'business',
            order_desc: "",
            list_packge_main: [
                {
                  "package_id": this.packageTypeSelected === 'M' ? "77a823b25ecd24a3d28664261e0b4e5f" : "c4db4cd8f1fdecbb6d48f82797a586ea"  ,
                  "quantity": this.storage_citizen
                }
              ],

            list_packge_addon: [],
            type_invoice:this.check_typeinvoice === 1 ? "Y" : "N"
          };
        }else{
          payload = {
            account_id: this.dataAccountId,
            data_id: this.dataAccountActive.type === 'Citizen' ? this.dataAccountId : this.dataAccountActive.business_info.business_id,
            type: this.dataAccountActive.type === 'Citizen' ? 'citizen': 'business',
            order_desc: "",
            list_packge_main: [
                {
                  "package_id": this.packageTypeSelected === 'M' ? "77a823b25ecd24a3d28664261e0b4e5f" : "c4db4cd8f1fdecbb6d48f82797a586ea" ,
                  "quantity": this.storage_citizen
                }
              ],

            list_packge_addon: [],
            type_invoice:this.check_typeinvoice === 1 ? "Y" : "N"
          };
        }
        console.log("payload", payload);
        let auth = await gbfGenerate.generateToken();
        this.axios
          .post(process.env.VUE_APP_SERVICE_ADMIN_BOX + "/api/payment_package_storage", payload, {
            headers: { Authorization: auth.code },
          })
          .then((response) => {
            // console.log("res*", response.data.result);
            if (response.data.status === "OK") {
              console.log("response", response);
              this.URL_payment = response.data.result;
              this.gotopay();
            } else {
              Toast.fire({
                icon: "error",
                title: response.data.errorMessage,
              });
            }
          });
      }
    },
    fn_checkpackage() {
      let payload = {
        data_id: this.dataAccountActive.type === 'Citizen' ? this.dataAccountId : this.dataAccountActive.business_info.business_id,
        type: this.dataAccountActive.type === 'Citizen' ? 'citizen': 'business',
      };
      this.axios
        .post(
          process.env.VUE_APP_SERVICE_ADMIN_BOX + "/api/get_package_current",
          payload
          //  {headers: { Authorization: auth.code } }
        )
        .then((response) => {
          if (response.data.status === "OK") {
            // console.log("response#######", response);
            for (var i = 0; i < response.data.details_package.length; i++) {
              let datapackage = {};
              datapackage["number_user"] = response.data.details_package[i].number_user;
              datapackage["package_id"] = response.data.details_package[i].package_id;
              datapackage["package_name"] = response.data.details_package[i].package_name;
              datapackage["package_name_th"] = response.data.details_package[i].package_name_th;
              datapackage["package_price"] = response.data.details_package[i].package_price;
              datapackage["package_storage"] = response.data.details_package[i].package_storage;
              datapackage["period"] = response.data.details_package[i].period;
              datapackage["status_payment"] = response.data.details_package[i].status_payment;
              datapackage["type_package"] = response.data.details_package[i].type_package;
              datapackage["type_period"] = response.data.details_package[i].type_period;
              datapackage["quantity"] = response.data.details_package[i].quantity;

              this.listpackage_current.push(datapackage);
            }
            console.log("this.listpackage_current",this.listpackage_current);
          } else {
            Toast.fire({
              icon: "error",
              title: response.data.errorMessage,
            });
          }
        });
    },
    gotopay() {
      window.open(this.URL_payment, "_blank");
      // window.open(this.URL_payment, "_self");
    },
    fn_goto_mypackage() {
      this.$router.replace({ path: "/mypackage" });
    },
    fn_goto_packagehistory() {
      this.$router.replace({ path: "/packagehistory" });
    },
    fn_tax_invoice(){
      this.check_typeinvoice = 2;
      this.opendialogtaxinvioce = true;
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  },
  mounted() {
    // this.getdatapackage(this.packageTypeSelected);
    this.fn_getstorage_user();
    this.fn_checkpackage();
    this.fn_check_tax_invoice();
  },
};
</script>

<style scoped>
#thiscontainer_package_current {
  height: calc(101vh - 180px);
  /* height: calc(var(--vh, 1vh) * 100); */
  overflow-x: hidden;
  /* max-width: 100%; */
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
}
#borderradius {
  border-radius: 15px;
}
#borderradius_card {
  border-radius: 26px;
}
.primary {
  background-color: #1279bc !important;
  border-color: #1279bc !important;
}
</style>
